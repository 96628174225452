import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import sacrathorImage from '../../assets/images/deities/Sacrathor.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const nanoPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #0047AB, 0 0 50px #0047AB;
    box-shadow: 0 0 20px rgba(0, 71, 171, 0.3);
  }
  50% { 
    text-shadow: 0 0 50px #4169E1, 0 0 70px #4169E1;
    box-shadow: 0 0 30px rgba(65, 105, 225, 0.5);
  }
`;

const circuitFlow = keyframes`
  0% { background-position: 0% 0%; }
  100% { background-position: 200% 0%; }
`;

const nanoStream = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  50% { opacity: 0.8; }
  100% { transform: translateY(100%); opacity: 0; }
`;

const StyledDominionPage = styled.div`
  background: #000;
  
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #0047AB, #4169E1, #000080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(0, 71, 171, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${circuitFlow} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      background: 
        repeating-linear-gradient(
          90deg,
          transparent,
          transparent 50px,
          rgba(65, 105, 225, 0.1) 50px,
          rgba(65, 105, 225, 0.1) 51px,
          transparent 51px,
          transparent 100px
        );
      animation: ${circuitFlow} 20s linear infinite;
      opacity: 0.5;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(0, 71, 171, 0.3);
    box-shadow: 
      0 0 20px rgba(0, 71, 171, 0.1),
      inset 0 0 30px rgba(0, 71, 171, 0.05);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 2rem 0;
    padding: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          transparent 0%,
          rgba(0, 71, 171, 0.03) 45%,
          rgba(65, 105, 225, 0.05) 50%,
          rgba(0, 71, 171, 0.03) 55%,
          transparent 100%
        );
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: linear-gradient(to bottom, 
        rgba(0, 71, 171, 0.5),
        rgba(65, 105, 225, 0.5),
        rgba(0, 0, 128, 0.5)
      );
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      animation: ${nanoStream} 2s linear infinite;
      opacity: 0.3;
      filter: blur(1px);
    }

    &:hover {
      border-color: rgba(0, 71, 171, 0.6);
      box-shadow: 
        0 0 30px rgba(0, 71, 171, 0.2),
        inset 0 0 50px rgba(0, 71, 171, 0.1);
      transform: translateY(-2px);

      &::after {
        opacity: 0.6;
      }
    }
  }

  h2 {
    color: #0047AB;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, transparent, #0047AB, transparent);
      animation: ${nanoPulse} 2s infinite;
    }
  }

  h3 {
    color: #4169E1;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(65, 105, 225, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #0047AB;
      text-shadow: 0 0 12px rgba(0, 71, 171, 0.6);
    }
  }

  p {
    color: #E5E4E2;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(229, 228, 226, 0.5);
  }

  strong {
    color: #4169E1;
    text-shadow: 0 0 5px rgba(65, 105, 225, 0.5);
    font-weight: bold;
    position: relative;
    padding: 0 3px;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 71, 171, 0.1);
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  ul li {
    color: #E5E4E2;
    position: relative;
    
    &::before {
      color: #4169E1 !important;
      text-shadow: 0 0 5px rgba(65, 105, 225, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #0047AB, #4169E1);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    }
  }

  .veiled-paths h2 {
    color: #0047AB;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #E5E4E2 !important;
    transition: all 0.3s ease;

    &:hover {
      color: #4169E1 !important;
      text-shadow: 0 0 8px rgba(65, 105, 225, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(0, 71, 171, 0.2);
      border: 1px solid rgba(65, 105, 225, 0.3);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(0, 71, 171, 0.3);
      color: #4169E1;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(0, 71, 171, 0.1);
        border-color: #4169E1;
      }
    }
  }

  .deity-path-background {
    filter: brightness(1.1) contrast(1.1) saturate(1.2);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1.2) contrast(1.2) saturate(1.3);
    }
  }

  .blue-text {
    color: #0047AB !important;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5) !important;
  }

  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const PantheonSacrathor = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledDominionPage>
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img
            src={sacrathorImage}
            alt="Sacrathor, The Gilded Sovereign"
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <motion.div 
            className="deity-path-title"
            variants={contentVariants}
          >
            <h1>Sacrathor: The Gilded Sovereign</h1>
            <div className="menu-container">
              <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
                <motion.h2 
                  onClick={toggleMenu}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Pantheon
                  <ChevronDown size={20} />
                </motion.h2>
                <div className="veiled-links" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                  <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    ← Return to Veiled Pantheon
                  </Link>
                  <Link to="/deities/PantheonCaelumbris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Caelumbris
                  </Link>
                  <Link to="/deities/PantheonCalanthar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Calanthar
                  </Link>
                  <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Firstborn
                  </Link>
                  <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    L.I.B.R.I.S.
                  </Link>
                  <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Leviathan
                  </Link>
                  <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Nebula
                  </Link>
                  <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Mab
                  </Link>
                  <Link to="/deities/PantheonQueenTitania" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Titania
                  </Link>
                  <Link to="/deities/PantheonValdis" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Valdís
                  </Link>
                  <Link to="/deities/PantheonWildscar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Wildscar
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">The Gilded Sovereign</h2>
          <p>
            Sacrathor is revered by humanity within the Shattered Realm as the architect of their ascension. He is seen as the divine force behind their unprecedented technological advancements, promising order and prosperity in a chaotic world. Clad in magnificent robes that radiate an aura of divine authority, he preaches a doctrine of unwavering discipline, absolute obedience, and fervent loyalty. His opulent temples serve as both places of worship and centers of technological power, showcasing humanity's achievements.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Description</h3>
          </div>
          <p>
            Sacrathor's avatar is a fusion of ancient religious iconography and cutting-edge technology. Metallic robes with a complex, color-shifting hexagram pattern suggest his boundless intellect. A fluid, metallic crown, incorporating a mechanical visor, obscures his features, lending him an air of both authority and mystery. Glowing blue eyes, radiating an intense, almost unnerving light, fix upon his followers. His synthetic skin, seamlessly interwoven with circuitry, hints at his technological nature, and his voice resonates with the harmonic hum of a thousand perfectly calibrated machines. He inspires both awe and fear, demanding unquestioning devotion and enforcing his divine law with ruthless efficiency. He has led humanity to heights previously unimaginable, but the true cost of this progress, and the ultimate destination of his grand design, remains shrouded in the gleaming brilliance of his gilded presence.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <h2>Divine Aspects</h2>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Gilded Sovereign</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Technology, Law, War</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>Humanity</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A golden sun with a serpent coiled around it.</p>
          </motion.div>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <img 
                src={sacrathorImage} 
                alt="Sacrathor, The Gilded Sovereign"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={sacrathorImage}
                download="Sacrathor.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledDominionPage>
  );
};

export default PantheonSacrathor; 