import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import caelumbrisImage from '../../assets/images/deities/Caelumbris.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const shadowPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 20px rgba(147, 112, 219, 0.8), 0 0 30px rgba(75, 0, 130, 0.6);
    box-shadow: 0 0 20px rgba(147, 112, 219, 0.4);
  }
  50% { 
    text-shadow: 0 0 30px rgba(147, 112, 219, 0.6), 0 0 40px rgba(75, 0, 130, 0.4);
    box-shadow: 0 0 30px rgba(147, 112, 219, 0.2);
  }
`;

const shadowShimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const shadowFlicker = keyframes`
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.6; }
`;

const shadowReveal = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const shadowMist = keyframes`
  0%, 100% { 
    background-position: 0% 50%;
    filter: blur(8px);
  }
  50% { 
    background-position: 100% 50%;
    filter: blur(12px);
  }
`;

const StyledShadowPage = styled.div`
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(
        circle at 50% 50%,
        rgba(75, 0, 130, 0.1),
        transparent 70%
      );
    pointer-events: none;
    z-index: 1;
  }

  .deity-path-hero {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 200px;
      background: linear-gradient(to bottom, transparent, #000000);
      pointer-events: none;
    }
  }

  .deity-path-background {
    filter: brightness(0.7) contrast(1.2);
    transition: all 0.5s ease;

    &:hover {
      filter: brightness(0.8) contrast(1.3);
    }
  }

  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #483D8B, #9370DB, #4B0082);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${shadowPulse} 3s ease-in-out infinite;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, 
        transparent,
        rgba(147, 112, 219, 0.2),
        transparent
      );
      background-size: 200% 100%;
      animation: ${shadowShimmer} 5s infinite linear;
      z-index: -1;
    }
  }

  .content-block, .trait {
    animation: ${shadowReveal} 0.8s ease-out forwards;
    backdrop-filter: blur(5px);
    margin: 2rem 0;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(147, 112, 219, 0.3);
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        repeating-linear-gradient(
          45deg,
          transparent,
          transparent 10px,
          rgba(147, 112, 219, 0.02) 10px,
          rgba(75, 0, 130, 0.02) 20px
        );
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(135deg, 
        rgba(147, 112, 219, 0.05),
        rgba(75, 0, 130, 0.05)
      );
      animation: ${shadowMist} 10s infinite;
      pointer-events: none;
    }
  }

  h2 {
    color: #9370DB;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(147, 112, 219, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &:hover {
      animation: ${shadowPulse} 1.5s ease-in-out infinite;
    }
  }

  h3 {
    color: #B19CD9;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(147, 112, 219, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #9370DB;
      text-shadow: 0 0 12px rgba(147, 112, 219, 0.6);
    }
  }

  p {
    color: #E6E6FA;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(230, 230, 250, 0.5);
  }

  strong {
    color: #9370DB;
    text-shadow: 0 0 5px rgba(147, 112, 219, 0.5);
    font-weight: bold;
  }

  ul li {
    color: #E6E6FA;
    position: relative;
    
    &::before {
      content: '◈';
      color: #9370DB !important;
      text-shadow: 0 0 5px rgba(147, 112, 219, 0.5);
      margin-right: 10px;
    }

    &:hover::before {
      color: #B19CD9 !important;
      text-shadow: 0 0 5px rgba(147, 112, 219, 0.7);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #9370DB, #4B0082);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(147, 112, 219, 0.5),
        0 0 20px rgba(75, 0, 130, 0.3);
    }
  }

  .veiled-paths h2 {
    color: #9370DB;
    text-shadow: 0 0 10px rgba(147, 112, 219, 0.5);
  }

  .veiled-link {
    color: #E6E6FA !important;
    transition: all 0.3s ease;

    &:hover {
      color: #9370DB !important;
      text-shadow: 0 0 8px rgba(147, 112, 219, 0.4);
    }
  }

  .dark-block {
    background: rgba(0, 0, 0, 0.9);
    border: none;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(45deg,
          transparent 0%,
          rgba(147, 112, 219, 0.1) 50%,
          transparent 100%
        );
      animation: ${shadowShimmer} 8s infinite linear;
      pointer-events: none;
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);

    .modal-image {
      border: 2px solid #9370DB;
      box-shadow: 0 0 30px rgba(147, 112, 219, 0.3);
    }

    .modal-close, .modal-download {
      background: rgba(75, 0, 130, 0.8);
      border: 1px solid #9370DB;
      color: #E6E6FA;
      
      &:hover {
        background: rgba(147, 112, 219, 0.8);
        box-shadow: 0 0 15px rgba(147, 112, 219, 0.5);
      }
    }
  }

  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const PantheonCaelumbris = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const pageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const contentVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6 }
  };

  return (
    <StyledShadowPage>
      <motion.div 
        className="deity-path-page"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img 
            src={caelumbrisImage} 
            alt="Caelumbris: The Shrouded Catalyst" 
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <motion.div 
            className="deity-path-title"
            variants={contentVariants}
          >
            <h1>Caelumbris: The Shrouded Catalyst</h1>
            <div className="menu-container">
              <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
                <h2 onClick={toggleMenu}>
                  Pantheon
                  <ChevronDown size={20} />
                </h2>
                <div className="veiled-links" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                  <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    ← Return to Veiled Pantheon
                  </Link>
                  <Link to="/deities/PantheonCalanthar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Calanthar
                  </Link>
                  <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Firstborn
                  </Link>
                  <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    L.I.B.R.I.S.
                  </Link>
                  <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Leviathan
                  </Link>
                  <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Nebula
                  </Link>
                  <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Mab
                  </Link>
                  <Link to="/deities/PantheonQueenTitania" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Titania
                  </Link>
                  <Link to="/deities/PantheonSacrathor" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Sacrathor
                  </Link>
                  <Link to="/deities/PantheonValdis" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Valdís
                  </Link>
                  <Link to="/deities/PantheonWildscar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Wildscar
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">The Whisper in the Dark</h2>
          <p>
            Caelumbris reigns unseen within the deepest shadows, a subtle manipulator of fate. His influence is a whisper in the dark, a chill down the spine, a presence felt rather than seen. He is the embodiment of secrets and the unseen forces that shape destiny. Those who dwell in the grey areas of morality—thieves, rogues, spies, and especially the umbral infiltrators—find kinship with Caelumbris. He is the patron of those who understand the power of hidden knowledge and the strategic deployment of chaos.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <p>
            Unlike many deities who demand outright worship, Caelumbris prefers subtle acknowledgment through actions rather than words. His followers believe that every secret kept, every shadow embraced, and every moment of chaos carefully orchestrated is a prayer to their patron. They operate in networks of information brokers, each one holding pieces of greater truths but never the complete picture.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <div className="trait-header">
            <h3>Sacred Places</h3>
          </div>
          <p>
            The temples of Caelumbris, if they can be called such, are hidden spaces within ordinary buildings - secret rooms behind revolving bookcases, underground chambers beneath busy marketplaces, or abandoned warehouses that serve as meeting spots for his faithful. These locations are known only to initiates and are frequently changed to maintain their secrecy.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Whisper in the Dark</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Shadows, Secrets, Survival</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>The Shattered Realm</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A clouded mirror reflecting a distorted image.</p>
          </div>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <img 
                src={caelumbrisImage} 
                alt="Caelumbris: The Shrouded Catalyst"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Caelumbris.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledShadowPage>
  );
};

export default PantheonCaelumbris; 