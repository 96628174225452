import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import valdisImage from '../../assets/images/deities/valdis.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const divineFlare = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #FFF, 0 0 50px #FFF, 0 0 70px #FFD700;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  }
  50% { 
    text-shadow: 0 0 20px #8B0000, 0 0 35px #8B0000, 0 0 50px #FF0000;
    box-shadow: 0 0 30px rgba(139, 0, 0, 0.5);
  }
`;

const haloGlow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 15px rgba(255, 215, 0, 0.8)); }
  50% { filter: drop-shadow(0 0 25px rgba(255, 215, 0, 0.4)); }
`;

const infernalPulse = keyframes`
  0% { background-position: 0% 0%; }
  100% { background-position: 200% 0%; }
`;

const StyledJusticePage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FFD700, #FFFFFF, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(255, 215, 0, 0.2),
          rgba(139, 0, 0, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${infernalPulse} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      content: '☩';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      background: linear-gradient(to bottom, #FFD700, #FF0000);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${haloGlow} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.8);
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #FFD700, #8B0000) 1;
    box-shadow: 
      0 0 20px rgba(255, 215, 0, 0.1),
      inset 0 0 30px rgba(139, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          rgba(255, 215, 0, 0.05) 0%,
          rgba(139, 0, 0, 0.05) 50%,
          rgba(255, 215, 0, 0.05) 100%
        );
      pointer-events: none;
    }

    &:hover {
      border-image: linear-gradient(45deg, #8B0000, #FFD700) 1;
      box-shadow: 
        0 0 30px rgba(139, 0, 0, 0.2),
        inset 0 0 50px rgba(255, 215, 0, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #FFD700;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #8B0000, #FFD700, #8B0000);
      animation: ${divineFlare} 3s infinite;
    }
  }

  h3 {
    background: linear-gradient(45deg, #FFD700, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      text-shadow: 
        0 0 10px rgba(255, 215, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  p {
    color: #E5E4E2;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(229, 228, 226, 0.5);
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FFD700, #8B0000);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 215, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FFD700, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #E5E4E2 !important;
    transition: all 0.3s ease;

    &:hover {
      background: linear-gradient(45deg, #FFD700, #8B0000);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 0 8px rgba(255, 215, 0, 0.4);
    }
  }

  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const PantheonValdis = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledJusticePage className="deity-path-page">
      <div className="deity-path-hero">
        <img
          src={valdisImage}
          alt="Valdis, The Phoenix of Justice"
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Valdis, The Phoenix of Justice</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Leviathan
                </Link>
                <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula
                </Link>
                <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonWildscar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">The Phoenix of Justice</h2>
        <p>
          In the aftermath of a cataclysm known as the Shattering, which splintered the very essence of reality, the cosmos itself seemed to weep for the loss of innumerable souls, their voices silenced in an instant. The fabric of reality, torn asunder, left behind a realm of fragments and echoes. It was within this realm of chaos and remnants that Valdis was born—not of flesh and blood, but of the collective yearning for justice and protection harbored by the lost. She is the phoenix rising from the ashes of despair, a deity forged from the collective memories and indomitable spirit of those who perished.
        </p>
      </div>

      <h2>Divine Aspects</h2>
      <div className="trait">
        <div className="trait-header">
          <h3>Alternate Names</h3>
        </div>
        <p>The Protector of the Lost, The Spirit of Justice</p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Domains</h3>
        </div>
        <p>Justice, Protection, Memory</p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Pantheon</h3>
        </div>
        <p>All</p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Symbol</h3>
        </div>
        <p>A halo floating above devil's horns.</p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={valdisImage} 
              alt="Valdis, The Phoenix of Justice"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={valdisImage}
              download="Valdis.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledJusticePage>
  );
};

export default PantheonValdis; 