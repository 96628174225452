import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import calantharImage from '../../assets/images/deities/Calanthar.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

// Keyframe animations for shattered glass effects
const shimmerEffect = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const pulseGlow = keyframes`
  0%, 100% { box-shadow: 0 0 15px rgba(135, 206, 250, 0.3), 0 0 30px rgba(135, 206, 250, 0.2); }
  50% { box-shadow: 0 0 25px rgba(135, 206, 250, 0.5), 0 0 50px rgba(135, 206, 250, 0.3); }
`;

const shardFloat = keyframes`
  0%, 100% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-5px) rotate(1deg); }
  50% { transform: translateY(0px) rotate(0deg); }
  75% { transform: translateY(5px) rotate(-1deg); }
`;

const reflectionMove = keyframes`
  0% { background-position: -100% 0%; }
  50% { background-position: 200% 0%; }
  100% { background-position: -100% 0%; }
`;

// Dynamic crack propagation animation
const crackPropagation = keyframes`
  0% { 
    clip-path: polygon(
      0% 15px, 15px 0%, calc(25% - 10px) 0%, 25% 15px, 
      calc(40% - 5px) 15px, 40% 0%, calc(50% + 15px) 0%, 
      50% 15px, calc(90% - 15px) 15px, 90% 0%, 100% 15px, 
      100% calc(100% - 15px), calc(100% - 15px) 100%, 
      calc(75% + 5px) 100%, 75% calc(100% - 10px), 
      calc(48% + 2px) calc(100% - 10px), 48% 100%, 
      calc(35% - 5px) 100%, 35% calc(100% - 10px), 
      calc(20% + 5px) calc(100% - 10px), 20% 100%, 
      15px 100%, 0% calc(100% - 15px)
    );
    transform: scale(1);
  }
  50% {
    clip-path: polygon(
      0% 10%, 10% 0%, 23% 5%, 38% 0%, 
      60% 8%, 75% 0%, 90% 15%, 100% 30%, 
      98% 55%, 100% 70%, 90% 90%, 70% 100%, 
      48% 95%, 35% 100%, 20% 90%, 10% 80%, 0% 60%
    );
    transform: scale(1.01);
  }
  100% { 
    clip-path: polygon(
      0% 15px, 15px 0%, calc(25% - 10px) 0%, 25% 15px, 
      calc(40% - 5px) 15px, 40% 0%, calc(50% + 15px) 0%, 
      50% 15px, calc(90% - 15px) 15px, 90% 0%, 100% 15px, 
      100% calc(100% - 15px), calc(100% - 15px) 100%, 
      calc(75% + 5px) 100%, 75% calc(100% - 10px), 
      calc(48% + 2px) calc(100% - 10px), 48% 100%, 
      calc(35% - 5px) 100%, 35% calc(100% - 10px), 
      calc(20% + 5px) calc(100% - 10px), 20% 100%, 
      15px 100%, 0% calc(100% - 15px)
    );
    transform: scale(1);
  }
`;

// Glass refraction effect
const refractLight = keyframes`
  0% { filter: blur(0px) brightness(1); }
  50% { filter: blur(0.3px) brightness(1.1); }
  100% { filter: blur(0px) brightness(1); }
`;

// Micro-shard vibration
const microVibration = keyframes`
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-0.2px, 0.2px) rotate(0.1deg); }
  20% { transform: translate(0.1px, -0.1px) rotate(-0.1deg); }
  30% { transform: translate(-0.1px, -0.2px) rotate(0.2deg); }
  40% { transform: translate(0.2px, 0.1px) rotate(-0.2deg); }
  50% { transform: translate(-0.2px, -0.1px) rotate(0.1deg); }
  60% { transform: translate(0.1px, 0.2px) rotate(-0.1deg); }
  70% { transform: translate(-0.1px, 0.1px) rotate(0.2deg); }
  80% { transform: translate(0.2px, -0.2px) rotate(-0.2deg); }
  90% { transform: translate(-0.2px, 0.1px) rotate(0.1deg); }
`;

// Main styled component with shattered glass theme
const StyledCalantharPage = styled.div`
  position: relative;
  min-height: 100vh;
  color: #e1ebf2;
  background: #040b14;
  overflow-x: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(135deg, rgba(4, 11, 20, 0.92), rgba(10, 26, 45, 0.95)),
      radial-gradient(circle at 20% 30%, rgba(25, 69, 107, 0.3) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(63, 114, 175, 0.3) 0%, transparent 50%);
    z-index: -1;
  }

  h1, h2, h3, h4 {
    color: #7ec8ff;
    position: relative;
    text-shadow: 
      0px 0px 5px rgba(135, 206, 250, 0.5),
      0px 0px 10px rgba(135, 206, 250, 0.3);
  }

  h1 {
    font-size: 4rem;
    letter-spacing: 4px;
    margin-bottom: 2rem;
    background: linear-gradient(45deg, #7ec8ff, #ffffff, #a5d6ff, #ffffff, #7ec8ff);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shimmerEffect} 5s linear infinite;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    &.blue-text {
      color: #7ec8ff;
      text-shadow: 0 0 10px rgba(135, 206, 250, 0.5);
    }
  }

  h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  p {
    color: #d5e5f2;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    position: relative;
  }

  .deity-path-hero {
    position: relative;
    
    img.deity-path-background {
      transition: filter 0.3s ease;
      filter: brightness(0.9) contrast(1.1) saturate(1.1) drop-shadow(0 0 20px rgba(135, 206, 250, 0.3));
      
      &:hover {
        filter: brightness(1) contrast(1.2) saturate(1.2) drop-shadow(0 0 30px rgba(135, 206, 250, 0.5));
      }
    }
  }

  // Shattered glass content blocks
  .content-block, .trait {
    margin: 2rem 0;
    padding: 2rem;
    position: relative;
    backdrop-filter: blur(5px);
    overflow: hidden;
    
    // Base glass appearance
    background: 
      linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05)),
      linear-gradient(to right, rgba(25, 69, 107, 0.2), rgba(63, 114, 175, 0.2));
    box-shadow: 
      0 4px 30px rgba(0, 0, 0, 0.3),
      inset 0 0 15px rgba(135, 206, 250, 0.15);
    
    // Glass border effect
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-right: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    
    // Clip path for shattered appearance
    clip-path: polygon(
      0% 15px, 15px 0%, calc(25% - 10px) 0%, 25% 15px, 
      calc(40% - 5px) 15px, 40% 0%, calc(50% + 15px) 0%, 
      50% 15px, calc(90% - 15px) 15px, 90% 0%, 100% 15px, 
      100% calc(100% - 15px), calc(100% - 15px) 100%, 
      calc(75% + 5px) 100%, 75% calc(100% - 10px), 
      calc(48% + 2px) calc(100% - 10px), 48% 100%, 
      calc(35% - 5px) 100%, 35% calc(100% - 10px), 
      calc(20% + 5px) calc(100% - 10px), 20% 100%, 
      15px 100%, 0% calc(100% - 15px)
    );
    
    transition: all 0.3s ease;
    animation: ${pulseGlow} 8s infinite ease-in-out;
    will-change: transform, clip-path;
    
    // Dynamic crack propagation on hover/active states
    &:hover {
      animation: ${pulseGlow} 8s infinite ease-in-out;
      box-shadow: 
        0 8px 32px rgba(0, 0, 0, 0.4),
        inset 0 0 25px rgba(135, 206, 250, 0.25);
      transform: translateY(-2px) scale(1.005);
      
      &::before {
        animation: ${reflectionMove} 6s infinite linear;
      }
    }
    
    &:active {
      clip-path: polygon(
        0% 10%, 10% 0%, 23% 5%, 38% 0%, 
        60% 8%, 75% 0%, 90% 15%, 100% 30%, 
        98% 55%, 100% 70%, 90% 90%, 70% 100%, 
        48% 95%, 35% 100%, 20% 90%, 10% 80%, 0% 60%
      );
      transform: translateY(1px) scale(0.995);
      transition: transform 0.2s ease, clip-path 0.2s ease;
    }
    
    // Reflection effect
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg, 
        transparent, 
        rgba(255, 255, 255, 0.1), 
        rgba(255, 255, 255, 0.15), 
        rgba(255, 255, 255, 0.1), 
        transparent
      );
      background-size: 200% 100%;
      pointer-events: none;
      animation: ${reflectionMove} 10s infinite linear;
      z-index: 1;
    }
    
    // Fracture lines
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(135deg, transparent 98%, rgba(135, 206, 250, 0.3) 99%, transparent 100%),
        linear-gradient(45deg, transparent 97%, rgba(135, 206, 250, 0.3) 98%, transparent 100%),
        linear-gradient(-45deg, transparent 96%, rgba(135, 206, 250, 0.3) 97%, transparent 100%),
        linear-gradient(-135deg, transparent 95%, rgba(135, 206, 250, 0.3) 96%, transparent 100%);
      background-size: 150px 150px, 170px 170px, 130px 130px, 160px 160px;
      background-position: -50px -50px, 30px 30px, -20px 20px, 40px -40px;
      pointer-events: none;
      opacity: 0.5;
      z-index: 2;
      
      // Micro-vibration on hover to simulate instability
      .content-block:hover &, .trait:hover & {
        animation: ${microVibration} 2s infinite ease-in-out;
      }
    }
  }

  // Adding micro-shards that appear on hover/interaction
  .content-block:hover::before, .trait:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: 
      radial-gradient(circle at 20% 80%, rgba(135, 206, 250, 0.15) 0%, transparent 20%),
      radial-gradient(circle at 80% 20%, rgba(135, 206, 250, 0.15) 0%, transparent 20%),
      radial-gradient(circle at 50% 50%, rgba(135, 206, 250, 0.1) 0%, transparent 30%);
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    pointer-events: none;
    z-index: 0;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  // Specialized styling for different block types
  .dark-block {
    background: 
      linear-gradient(135deg, rgba(255,255,255,0.07), rgba(255,255,255,0.02)),
      linear-gradient(to right, rgba(16, 42, 67, 0.3), rgba(40, 73, 112, 0.3));
    box-shadow: 
      0 4px 30px rgba(0, 0, 0, 0.4),
      inset 0 0 15px rgba(135, 206, 250, 0.1);
    
    &::after {
      opacity: 0.3;
    }
  }

  // Prismatic edge effect styles
  .prismatic-edge {
    clip-path: inherit;
    
    &::before {
      opacity: 0;
    }
  }
  
  .content-block:hover .prismatic-edge::before,
  .trait:hover .prismatic-edge::before {
    opacity: 0.15;
    animation: prismaticShift 3s infinite linear;
  }
  
  @keyframes prismaticShift {
    0% { background-position: 0% 50%; background-size: 200% 200%; }
    50% { background-position: 100% 50%; background-size: 200% 200%; }
    100% { background-position: 0% 50%; background-size: 200% 200%; }
  }

  // Interaction audio effects
  .content-block, .trait {
    cursor: pointer;
    
    &:active {
      transition: transform 0.2s ease, clip-path 0.2s ease;
    }
  }

  // Shard decorations for the page
  .shard-decoration {
    position: absolute;
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    z-index: -1;
    animation: ${shardFloat} 8s infinite ease-in-out;
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;
    overflow: visible;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #7ec8ff, #3f72af);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(135, 206, 250, 0.5);
    }
  }

  .veiled-paths h2 {
    color: #7ec8ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #FFFFFF;
      text-shadow: 0 0 10px rgba(135, 206, 250, 0.5);
    }
  }

  .veiled-link {
    color: #d5e5f2;
    text-decoration: none;
    transition: all 0.3s ease;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    position: relative;
    margin: 0.3rem 0;
    display: block;

    &:hover {
      color: #7ec8ff;
      text-shadow: 0 0 8px rgba(135, 206, 250, 0.4);
      padding-left: 5px;
    }

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 1px;
      background: #7ec8ff;
      transition: width 0.3s ease;
    }

    &:hover::before {
      width: 8px;
    }
  }

  // Modal styling with glass effect
  .image-modal {
    background: rgba(4, 11, 20, 0.85);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(135, 206, 250, 0.3);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(135, 206, 250, 0.3);
      color: #7ec8ff;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(135, 206, 250, 0.2);
        border-color: #7ec8ff;
      }
    }
  }
`;

// Decorative shard component
const GlassShard = styled.div`
  position: absolute;
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(135, 206, 250, 0.2);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  animation: ${shardFloat} 10s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
  
  // Adding prismatic edge effect
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(
      135deg,
      rgba(255, 0, 0, 0.1),
      rgba(255, 165, 0, 0.1),
      rgba(255, 255, 0, 0.1),
      rgba(0, 128, 0, 0.1),
      rgba(0, 0, 255, 0.1),
      rgba(75, 0, 130, 0.1),
      rgba(238, 130, 238, 0.1)
    );
    clip-path: inherit;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  &:hover::before {
    opacity: 0.5;
  }
`;

// Prismatic reflection component for adding to content blocks
const PrismaticEdge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(
      45deg,
      rgba(255, 0, 0, 0.05),
      rgba(255, 165, 0, 0.05),
      rgba(255, 255, 0, 0.05),
      rgba(0, 128, 0, 0.05),
      rgba(0, 0, 255, 0.05),
      rgba(75, 0, 130, 0.05),
      rgba(238, 130, 238, 0.05)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    clip-path: inherit;
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const PantheonCalanthar = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const pageRef = useRef(null);

  // Track mouse movement for parallax effects
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (pageRef.current) {
        const { width, height } = pageRef.current.getBoundingClientRect();
        const x = (e.clientX / width) - 0.5;
        const y = (e.clientY / height) - 0.5;
        setMousePosition({ x, y });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Glass crack sound effect on click (subtle)
    const handleGlassClick = (e) => {
      // Only play for content blocks and traits
      if (e.target.closest('.content-block') || e.target.closest('.trait')) {
        const audio = new Audio();
        audio.volume = 0.1; // Very subtle
        
        // Randomly select one of three subtle glass sounds
        const soundIndex = Math.floor(Math.random() * 3) + 1;
        
        // This would require adding sound files to your assets
        // For now, just mock the functionality - uncomment when you add the audio files
        // audio.src = `../../assets/sounds/glass-${soundIndex}.mp3`;
        // audio.play().catch(err => console.log('Audio play prevented:', err));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('click', handleGlassClick);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('click', handleGlassClick);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledCalantharPage ref={pageRef}>
      {/* Decorative glass shards with parallax effect */}
      <GlassShard 
        style={{ 
          top: '15%', 
          right: '5%', 
          width: '120px', 
          height: '120px', 
          opacity: 0.4, 
          animationDelay: '0s',
          transform: `translate(${mousePosition.x * -20}px, ${mousePosition.y * -20}px) rotate(${mousePosition.x * 5}deg)`
        }} 
      />
      <GlassShard 
        style={{ 
          top: '35%', 
          left: '3%', 
          width: '80px', 
          height: '80px', 
          opacity: 0.3, 
          animationDelay: '1s',
          transform: `translate(${mousePosition.x * 15}px, ${mousePosition.y * 15}px) rotate(${mousePosition.y * -3}deg)`
        }} 
      />
      <GlassShard 
        style={{ 
          bottom: '15%', 
          right: '8%', 
          width: '100px', 
          height: '100px', 
          opacity: 0.35, 
          animationDelay: '2s',
          transform: `translate(${mousePosition.x * -25}px, ${mousePosition.y * 10}px) rotate(${mousePosition.x * -2}deg)`
        }} 
      />
      <GlassShard 
        style={{ 
          bottom: '30%', 
          left: '7%', 
          width: '150px', 
          height: '150px', 
          opacity: 0.25, 
          animationDelay: '3s',
          transform: `translate(${mousePosition.x * 30}px, ${mousePosition.y * -15}px) rotate(${mousePosition.y * 4}deg)`
        }} 
      />
      
      {/* Additional micro shards that follow mouse movement more closely */}
      <GlassShard 
        style={{ 
          top: '50%', 
          left: '20%', 
          width: '40px', 
          height: '40px', 
          opacity: 0.2, 
          animationDelay: '0.5s',
          transform: `translate(${mousePosition.x * 40}px, ${mousePosition.y * 40}px) rotate(${mousePosition.x * 10}deg)`,
          transition: 'transform 0.1s ease'
        }} 
      />
      <GlassShard 
        style={{ 
          top: '25%', 
          right: '15%', 
          width: '30px', 
          height: '30px', 
          opacity: 0.15, 
          animationDelay: '1.5s',
          transform: `translate(${mousePosition.x * -35}px, ${mousePosition.y * 45}px) rotate(${mousePosition.y * -8}deg)`,
          transition: 'transform 0.1s ease'
        }} 
      />
    
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img
            src={calantharImage}
            alt="Calanthar: The All-Encapsulating Reality"
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <div className="deity-path-title">
            <h1>Calanthar: The All-Encapsulating Reality</h1>
            <div className="menu-container">
              <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
                <h2 onClick={toggleMenu}>
                  Pantheon
                  <ChevronDown size={20} />
                </h2>
                <div className="veiled-links" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                  <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    ← Return to Veiled Pantheon
                  </Link>
                  <Link to="/deities/PantheonCaelumbris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Caelumbris
                  </Link>
                  <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Firstborn
                  </Link>
                  <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    L.I.B.R.I.S.
                  </Link>
                  <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Leviathan
                  </Link>
                  <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Nebula
                  </Link>
                  <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Mab
                  </Link>
                  <Link to="/deities/PantheonQueenTitania" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Titania
                  </Link>
                  <Link to="/deities/PantheonSacrathor" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Sacrathor
                  </Link>
                  <Link to="/deities/PantheonValdis" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Valdís
                  </Link>
                  <Link to="/deities/PantheonWildscar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Wildscar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <h2 className="blue-text">The Universal Embodiment</h2>
          <p>
            Calanthar, within the lore of the Shattered Realm, stands as a unique and deeply intricate entity. Not just a god but the very embodiment of reality within this fractured cosmos, Calanthar's story weaves through the fabric of existence itself. His narrative is a confluence of omnipotence, isolation, and the quest for balance in a universe born from chaos.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <h2>Origins and Ascension</h2>
          <p>
            Calanthar is not merely a god; he is the embodiment of reality within the Shattered Realm, a singular universe created from the collapse of countless others. This unique cosmos is characterized by its unstable and intertwined nature, where fragments of disparate worlds blend into a complex tapestry. Once a mortal driven by an insatiable desire for ultimate understanding, Calanthar's transformation into a being fused with the essence of existence itself granted him immeasurable power—but also profound isolation and madness, as the weight of infinite realities bore down upon his consciousness. For millennia, he subtly influenced humanity's technological progress, acting as an unseen architect of their destiny.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>The Multiversal Awakening</h3>
          </div>
          <p>
            Amidst the endless cosmos, where stars whispered secrets of eternity, Calanthar found himself facing a monumental decision. His eyes, reflecting infinite galaxies, glowed with a power that transcended time and space as he prepared to expand his consciousness. With a deep breath, he connected his mind to every version of himself across the multiverse, weaving an intricate network of possibilities. In this transformative moment, Calanthar ceased to be merely mortal; he became a nexus of infinite selves, each contributing its knowledge and experiences to his being. The connection, however, brought both enlightenment and an overwhelming burden. The joys, sorrows, triumphs, and failures of countless lives bore down on him, threatening his sanity. In his pursuit of omniscience, Calanthar had entered uncharted realms, unaware of the cost to his soul.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>A Fragmented Existence</h3>
          </div>
          <p>
            Calanthar awoke in a strange and unfamiliar world, the ground beneath him cold and unyielding. The warm memories of loved ones had become distant echoes. Rising as a figure shrouded in confusion and loss, he wandered like a ghost through landscapes he had never seen. His mind, a tempest of ceaseless voices and visions from a thousand realities, was a cacophony of alternate selves clashing for dominance. Decades, perhaps centuries, passed as he roamed, his resolve and sense of identity crumbling. This prolonged period of disorientation ultimately sharpened his awareness, forcing him to adapt to the onslaught of sensory chaos. From this crucible, Calanthar emerged fundamentally changed. No longer entirely human, he had become an entity straddling the line between man and myth, tethered to a world that felt both familiar and alien.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>The Silent Architect</h3>
          </div>
          <p>
            For nearly a millennium, Calanthar remained a hidden force, guiding humanity through epochs of technological evolution. Operating from the shadows, he whispered insights to leaders and innovators, sowing the seeds of progress. Yet, for all his influence, Calanthar lived a solitary existence, guarding his immortality and vast knowledge as fiercely as the wisdom he imparted. He observed humanity's ascension with both pride and sorrow, increasingly alienated from those he uplifted. While his multiversal perspective provided unparalleled insight, it also rendered him an outsider, eternally gazing at lives he could never truly be part of. This dichotomy haunted him, and he often questioned whether he was still a participant in the grand drama of existence or merely a spectator.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>The Leviathan's Approach</h3>
          </div>
          <p>
            The cosmos's tranquility shattered with the arrival of a cryptic signal, heralding the approach of a leviathan—a massive entity dwarfing their moon. Calanthar had not foreseen this event, and its implications unsettled him. Among the halls of power, where his counsel had long been revered, chaos erupted. The signal, interpreted as a divine challenge, ignited fervent zealotry. The doctrines Calanthar had instilled to guide humanity toward progress had twisted into a weapon of fanaticism. His once-puppet council transformed into fervent zealots, interpreting the message as a decree from their god to wage a holy war. The savior of humanity had inadvertently become the architect of its potential downfall. Even as Calanthar worked to decipher the signal's ancient, cryptic language, its misinterpretation spread like wildfire. It became the foundation for a new scripture and a rallying cry for cosmic crusade. Calanthar's influence waned as his council moved with zeal that defied his manipulation. As the leviathan loomed ever closer, the very beliefs Calanthar had nurtured became an obstacle. The leviathan, he discerned, was not a divine adversary but a cosmic seedling—a colossal organism that roamed the stars, feeding and growing. Though massive and powerful, it was not invincible. Convincing his council to grant him oversight of the dimensional net project, a device designed to halt the leviathan's advance, was a small victory. Yet, time was short, and the project would be his final chance to steer events back on course.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>The Dimensional Net and Sacrifice</h3>
          </div>
          <p>
            The dimensional net, Calanthar's magnum opus, was a convergence of science and sorcery. It represented the culmination of his millennia of preparation. As the leviathan approached, anticipation and dread filled the air. Calanthar activated the net, and in an instant, the fabric of reality shifted. The leviathan was ensnared, but the consequences rippled across the multiverse. Realities began collapsing, folding into the pocket dimension created by the net. Calanthar, intertwined with the device, felt his essence stretch to encompass these unraveling worlds. He encountered a cosmic entity—the universe personified—appearing as a feminine form cloaked in galaxies. In her vast presence, Calanthar felt his insignificance yet defied her, channeling all his strength to stabilize the fragmenting universe. 'Not them!' he cried, his voice resonating through the collapsing void. With an unyielding determination, Calanthar channeled the unraveling realities, integrating them into his essence to safeguard the last fragments of his people and home. In this act of defiance and sacrifice, he transcended the role of a mere guardian, becoming a creator and the custodian of a fragile, precious new existence.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>The Shattered Realm</h3>
          </div>
          <p>
            In the aftermath, Calanthar found himself within the Shattered Realm—a mosaic of fragmented worlds bound together in a precarious equilibrium. No longer a man or wizard, he had transcended into a living embodiment of the realm itself. Every star, every planet, and every creature within this new reality was a fragment of his consciousness. The burden of his role weighed heavily, but within the solitude, Calanthar found a glimmer of hope. Here was an opportunity to reshape a world free from the shadows of fanaticism and fear. As the custodian of this reality, his journey was far from over; it was a chance to create a legacy that would echo through eternity.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>Presence and Representation</h3>
          </div>
          <p>
            In his ascended form, Calanthar manifests as a venerable figure, draped in humble, patchwork robes. His eyes, reflecting the cosmos, radiate an unearthly light, and his staff—fashioned from the remnants of collapsed realities—serves as both a weapon and a symbol of his cosmic authority. Every star, planet, and creature within the Shattered Realm is a fragment of his consciousness, a testament to his omnipresence and eternal vigilance.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>Dogma and Worship</h3>
          </div>
          <p>
            Calanthar's teachings emphasize the interconnectedness of all things and the pursuit of understanding. His followers, often scholars, mages, and philosophers, seek to unravel the mysteries of the cosmos while respecting its delicate balance. Temples dedicated to Calanthar are rare; his worship is expressed through the pursuit of knowledge and harmony with the universe.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <div className="trait-header">
            <h3>Eternal Vigilance and Legacy</h3>
          </div>
          <p>
            Calanthar's story is one of sacrifice, transformation, and eternal duty. As the Shattered Realm's custodian, he balances its myriad realities, ensuring that its infinite possibilities do not unravel into chaos. His existence serves as both a warning and an inspiration, a reminder of the cost of ambition and the boundless potential of understanding.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <PrismaticEdge className="prismatic-edge" />
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Universal Embodiment, The Weaver of Realities</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Cosmos, Knowledge, Creation</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>All</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A web intertwined with stars and fragmented realities.</p>
          </div>
        </motion.div>

        {/* Image Modal */}
        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            >
              <img 
                src={calantharImage} 
                alt="Calanthar: The All-Encapsulating Reality"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={calantharImage}
                download="Calanthar.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledCalantharPage>
  );
};

export default PantheonCalanthar;
