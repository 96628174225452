import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { GiGreekTemple, GiCrownOfThorns, GiCastle } from 'react-icons/gi';
import styled from 'styled-components';

const Nav = styled.nav`
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  padding: 10px 20px;
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);
  font-family: 'Cinzel', serif;
  border-top: 1px solid rgba(193, 154, 107, 0.2);

  .main-menu-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .fantasy-icon {
    transition: all 0.3s ease;
  }

  .active .fantasy-icon {
    color: #c19a6b;
    filter: drop-shadow(0 0 5px rgba(193, 154, 107, 0.5));
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
`;

const LogoSection = styled.div`
  display: none;
`;

const SiteTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #c19a6b;
  text-shadow: 0 0 10px rgba(193, 154, 107, 0.4);
  margin-left: 15px;
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const MenuButton = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.isOpen ? '#c19a6b' : '#fff'};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    color: #c19a6b;
  }
  
  &.active {
    color: #c19a6b;
  }
`;

const MenuLabel = styled.span`
  margin-top: 4px;
  font-size: 0.8rem;
  text-align: center;
`;

const MenuDropdown = styled.div`
  position: absolute;
  bottom: 100%;
  right: ${props => props.align === 'right' ? '0' : 'auto'};
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  border: 1px solid rgba(193, 154, 107, 0.3);
  min-width: 250px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 4px 4px 0 0;
  z-index: 1001;
  opacity: ${props => props.isOpen ? '1' : '0'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transform: translateY(${props => props.isOpen ? '0' : '10px'});
  transition: all 0.3s ease;
  max-height: ${props => props.isOpen ? '80vh' : '0'};
  overflow-y: auto;
  
  @media (max-width: 768px) {
    right: 0;
    width: 280px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(193, 154, 107, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(193, 154, 107, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(193, 154, 107, 0.5);
  }
`;

const MenuSection = styled.div`
  padding: 15px;
  border-bottom: 1px solid rgba(193, 154, 107, 0.2);
  
  &:last-child {
    border-bottom: none;
  }
`;

const SectionTitle = styled.h3`
  color: #c19a6b;
  margin: 0 0 10px 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(193, 154, 107, 0.2);
`;

const MenuItemsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MenuItem = styled.li`
  a {
    display: block;
    padding: 8px 12px;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease;
    cursor: pointer;
    
    &:hover {
      background: rgba(193, 154, 107, 0.2);
      color: #c19a6b;
    }
  }
`;

const Navigation = () => {
  const [isDeityMenuOpen, setIsDeityMenuOpen] = useState(false);
  const [isPantheonMenuOpen, setIsPantheonMenuOpen] = useState(false);
  const [isFirstVeilMenuOpen, setIsFirstVeilMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const deityMenuRef = useRef(null);
  const pantheonMenuRef = useRef(null);
  const firstVeilMenuRef = useRef(null);
  
  // Check if user has sufficient role
  const hasAccess = currentUser && 
    (currentUser.role === 'adventurer' || currentUser.role === 'admin');
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deityMenuRef.current && !deityMenuRef.current.contains(event.target)) {
        setIsDeityMenuOpen(false);
      }
      
      if (pantheonMenuRef.current && !pantheonMenuRef.current.contains(event.target)) {
        setIsPantheonMenuOpen(false);
      }

      if (firstVeilMenuRef.current && !firstVeilMenuRef.current.contains(event.target)) {
        setIsFirstVeilMenuOpen(false);
      }
    };
    
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setIsDeityMenuOpen(false);
        setIsPantheonMenuOpen(false);
        setIsFirstVeilMenuOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);
  
  const handleMenuToggle = (menuName) => {
    if (menuName === 'deity') {
      setIsDeityMenuOpen(!isDeityMenuOpen);
      setIsPantheonMenuOpen(false);
      setIsFirstVeilMenuOpen(false);
    } else if (menuName === 'pantheon') {
      setIsPantheonMenuOpen(!isPantheonMenuOpen);
      setIsDeityMenuOpen(false);
      setIsFirstVeilMenuOpen(false);
    } else if (menuName === 'firstVeil') {
      setIsFirstVeilMenuOpen(!isFirstVeilMenuOpen);
      setIsDeityMenuOpen(false);
      setIsPantheonMenuOpen(false);
    }
  };
  
  const handleNavigation = (path) => {
    if (!hasAccess && path.startsWith('/deity')) {
      alert("You need to be an Adventurer or Admin to access this content.");
      return;
    }
    
    navigate(path);
    setIsDeityMenuOpen(false);
    setIsPantheonMenuOpen(false);
    setIsFirstVeilMenuOpen(false);
  };
  
  // Define the deity paths
  const deityPaths = [
    { name: "Caelumbris Shadowed", path: "/deity/caelumbris-shadowed" },
    { name: "Libris Knowledge", path: "/deity/libris-knowledge" },
    { name: "Firstborn Soulbound", path: "/deity/firstborn-soulbound" },
    { name: "Nebula Mist Walker", path: "/deity/nebula-mist-walker" },
    { name: "Queen Mab Frozen Veil", path: "/deity/queen-mab-frozen-veil" },
    { name: "Queen Titania Wildgrowth", path: "/deity/queen-titania-wildgrowth" },
    { name: "Sacrathor Dominion", path: "/deity/sacrathor-dominion" },
    { name: "Unbound", path: "/deity/unbound" },
    { name: "Valdis Justice", path: "/deity/valdis-justice" },
    { name: "Wildscar Ember Fury", path: "/deity/wildscar-ember-fury" }
  ];
  
  // Define the pantheon paths
  const pantheonPaths = [
    { name: "Caelumbris", path: "/pantheon/caelumbris" },
    { name: "Calanthar", path: "/pantheon/calanthar" },
    { name: "Leviathan", path: "/pantheon/leviathan" },
    { name: "Firstborn", path: "/pantheon/firstborn" },
    { name: "Libris", path: "/pantheon/libris" },
    { name: "Nebula", path: "/pantheon/nebula" },
    { name: "Queen Mab", path: "/pantheon/queen-mab" },
    { name: "Queen Titania", path: "/pantheon/queen-titania" },
    { name: "Sacrathor", path: "/pantheon/sacrathor" },
    { name: "Valdis", path: "/pantheon/valdis" },
    { name: "Wildscar", path: "/pantheon/wildscar" }
  ];

  // Define First Veil paths
  const firstVeilPaths = [
    { name: "Profile", path: "/dashboard" },
    { name: "Help", path: "/help" },
    { name: "History", path: "/history" },
    { name: "House Rules", path: "/house-rules" }
  ];
  
  return (
    <Nav>
      <NavContainer>
        <LogoSection>
          <SiteTitle>Navigation</SiteTitle>
        </LogoSection>
        
        <NavButtons>
          <div ref={firstVeilMenuRef} className="main-menu-dropdown">
            <MenuButton 
              isOpen={isFirstVeilMenuOpen}
              onClick={() => handleMenuToggle('firstVeil')}
              className={isFirstVeilMenuOpen ? 'active' : ''}
            >
              <GiCastle size={24} className="fantasy-icon" />
              <MenuLabel>First Veil</MenuLabel>
            </MenuButton>
            
            <MenuDropdown isOpen={isFirstVeilMenuOpen} align="right">
              <MenuSection>
                <MenuItemsList>
                  {firstVeilPaths.map((item, index) => (
                    <MenuItem key={index}>
                      <a onClick={() => handleNavigation(item.path)}>
                        {item.name}
                      </a>
                    </MenuItem>
                  ))}
                </MenuItemsList>
              </MenuSection>
            </MenuDropdown>
          </div>

          {hasAccess && (
            <>
              <div ref={deityMenuRef} className="main-menu-dropdown">
                <MenuButton 
                  isOpen={isDeityMenuOpen}
                  onClick={() => handleMenuToggle('deity')}
                  className={isDeityMenuOpen ? 'active' : ''}
                >
                  <GiCrownOfThorns size={24} className="fantasy-icon" />
                  <MenuLabel>Veiled Paths</MenuLabel>
                </MenuButton>
                
                <MenuDropdown isOpen={isDeityMenuOpen} align="right">
                  <MenuSection>
                    <MenuItemsList>
                      {deityPaths.map((item, index) => (
                        <MenuItem key={index}>
                          <a onClick={() => handleNavigation(item.path)}>
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItemsList>
                  </MenuSection>
                </MenuDropdown>
              </div>
              
              <div ref={pantheonMenuRef} className="main-menu-dropdown">
                <MenuButton 
                  isOpen={isPantheonMenuOpen}
                  onClick={() => handleMenuToggle('pantheon')}
                  className={isPantheonMenuOpen ? 'active' : ''}
                >
                  <GiGreekTemple size={24} className="fantasy-icon" />
                  <MenuLabel>Pantheons</MenuLabel>
                </MenuButton>
                
                <MenuDropdown isOpen={isPantheonMenuOpen} align="right">
                  <MenuSection>
                    <MenuItemsList>
                      {pantheonPaths.map((item, index) => (
                        <MenuItem key={index}>
                          <a onClick={() => handleNavigation(item.path)}>
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItemsList>
                  </MenuSection>
                </MenuDropdown>
              </div>
            </>
          )}
        </NavButtons>
      </NavContainer>
    </Nav>
  );
};

export default Navigation; 