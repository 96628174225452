import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import wildscarImage from '../../assets/images/deities/wildscar.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const bloodDrip = keyframes`
  0% { transform: scaleY(0); opacity: 0; }
  50% { transform: scaleY(1); opacity: 0.6; }
  100% { transform: scaleY(0); opacity: 0; }
`;

const wrathPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #FF0000, 0 0 50px #FF0000;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
  }
  50% { 
    text-shadow: 0 0 20px #8B0000, 0 0 35px #8B0000;
    box-shadow: 0 0 30px rgba(139, 0, 0, 0.5);
  }
`;

const liquidBorder = keyframes`
  0% {
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000 45%, #8B0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
  }
  50% {
    border-image-source: linear-gradient(45deg, #FF0000, #8B0000 55%, #FF0000);
    box-shadow: 
      0 0 25px rgba(255, 0, 0, 0.4),
      inset 0 0 35px rgba(255, 0, 0, 0.3);
  }
  100% {
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000 45%, #8B0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
  }
`;

const emberGlow = keyframes`
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 0%; }
  100% { background-position: 0% 0%; }
`;

const fireFlicker = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
`;

const StyledPantheonWildscar = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FF0000, #FF4500, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(255, 0, 0, 0.2),
          rgba(139, 0, 0, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${emberGlow} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      content: '🔥';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      animation: ${fireFlicker} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.8);
    border: 3px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
    transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
    animation: ${liquidBorder} 4s ease-in-out infinite;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          rgba(255, 0, 0, 0.05) 0%,
          rgba(139, 0, 0, 0.05) 50%,
          rgba(255, 69, 0, 0.05) 100%
        );
      pointer-events: none;
      filter: blur(2px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: 
        repeating-linear-gradient(
          to right,
          transparent 0%,
          transparent 20%,
          rgba(139, 0, 0, 0.15) 20%,
          rgba(139, 0, 0, 0.15) 21%,
          transparent 21%,
          transparent 100%
        );
      mask-image: linear-gradient(to bottom, transparent, black);
      animation: ${bloodDrip} 3s infinite;
      opacity: 0.3;
      filter: blur(1px);
    }

    &:hover {
      border-image-source: linear-gradient(45deg, #FF0000, #8B0000);
      box-shadow: 
        0 0 30px rgba(255, 0, 0, 0.4),
        inset 0 0 40px rgba(139, 0, 0, 0.3);
      transform: translateY(-2px);

      &::after {
        opacity: 0.5;
      }
    }
  }

  h2 {
    color: #FF0000;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #8B0000, #FF0000, #8B0000);
      animation: ${wrathPulse} 3s infinite;
    }
  }

  h3 {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      text-shadow: 
        0 0 10px rgba(255, 0, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  p {
    color: #FFA07A;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 160, 122, 0.5);
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FF0000, #8B0000);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 0, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #FFA07A !important;
    transition: all 0.3s ease;

    &:hover {
      background: linear-gradient(45deg, #FF0000, #FF4500);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 0 8px rgba(255, 0, 0, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);

    .modal-image {
      border: 2px solid #FF0000;
      box-shadow: 0 0 30px rgba(255, 0, 0, 0.3);
    }

    .modal-close, .modal-download {
      background: rgba(139, 0, 0, 0.8);
      border: 1px solid #FF0000;
      color: #FFA07A;
      
      &:hover {
        background: rgba(255, 0, 0, 0.8);
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
      }
    }
  }
`;

const PantheonWildscar = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledPantheonWildscar className="deity-path-page">
      <div className="deity-path-hero">
        <img
          src={wildscarImage}
          alt="Wildscar, The Primal Fury"
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Wildscar, The Primal Fury</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Leviathan
                </Link>
                <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula
                </Link>
                <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">The Primal Fury</h2>
        <p>
          The Shattering ripped through the fabric of reality, unleashing forces beyond comprehension. Among these, Wildscar emerged, not merely a phenomenon but a primal fury incarnate. For decades, a formless red mist, it haunted the Shattered Realm, unseen yet deeply felt. Villages woke to days of madness, warriors turned their blades on allies, and tranquil havens became slaughterhouses—all swathed in its crimson embrace.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Description</h3>
        </div>
        <p>
          The Shattering ripped through the fabric of reality, unleashing forces beyond comprehension. Among these, Wildscar emerged, not merely a phenomenon but a primal fury incarnate. For decades, a formless red mist, it haunted the Shattered Realm, unseen yet deeply felt. Villages woke to days of madness, warriors turned their blades on allies, and tranquil havens became slaughterhouses—all swathed in its crimson embrace.
        </p>
      </div>

      <div className="content-block">
        <h2>Divine Aspects</h2>
        <div className="trait">
          <div className="trait-header">
            <h3>Alternate Names</h3>
          </div>
          <p>The Crimson Mist, The Unseen Fury</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Domains</h3>
          </div>
          <p>Chaos, Madness, War</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Pantheon</h3>
          </div>
          <p>All</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Symbol</h3>
          </div>
          <p>A Battle Axe made of blood.</p>
        </div>
      </div>

      {showFullImage && (
        <motion.div
          className="full-image-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setShowFullImage(false)}
        >
          <img src={wildscarImage} alt="Wildscar, The Primal Fury" className="full-image" />
        </motion.div>
      )}
    </StyledPantheonWildscar>
  );
};

export default PantheonWildscar; 