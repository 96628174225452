import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import queenTitaniaImage from '../../assets/images/deities/queentitania.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const fireGlow = keyframes`
  0%, 100% { text-shadow: 0 0 30px #FF4500, 0 0 50px #FF4500, 0 0 70px #FF4500; }
  50% { text-shadow: 0 0 20px #FF8C00, 0 0 35px #FF8C00, 0 0 50px #FF8C00; }
`;

const wildGrowth = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  25% { transform: scale(1.02) rotate(0.5deg); }
  50% { transform: scale(1) rotate(-0.5deg); }
  75% { transform: scale(1.02) rotate(0.5deg); }
  100% { transform: scale(1) rotate(0deg); }
`;

const flameFlicker = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
`;

const StyledWildgrowthPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FF4500, #FF8C00, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${fireGlow} 3s ease-in-out infinite;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, 
        transparent,
        rgba(255, 69, 0, 0.2),
        rgba(50, 205, 50, 0.2),
        transparent
      );
      background-size: 200% 100%;
      animation: ${wildGrowth} 5s ease-in-out infinite;
      z-index: -1;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 69, 0, 0.3);
    box-shadow: 0 0 20px rgba(255, 69, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 2rem 0;
    padding: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(135deg,
          transparent,
          rgba(255, 69, 0, 0.05) 40%,
          rgba(50, 205, 50, 0.05) 60%,
          transparent
        );
      animation: ${flameFlicker} 3s infinite;
      pointer-events: none;
    }

    &:hover {
      border-image: linear-gradient(45deg, #FF4500, #32CD32) 1;
      box-shadow: 
        0 0 20px rgba(255, 69, 0, 0.2),
        0 0 30px rgba(50, 205, 50, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #FF4500;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 69, 0, 0.5);
    margin-bottom: 1rem;
    
    &:hover {
      animation: ${fireGlow} 1.5s ease-in-out infinite;
    }
  }

  h3 {
    color: #FF8C00;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(255, 140, 0, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #FF4500;
      text-shadow: 0 0 12px rgba(255, 69, 0, 0.6);
    }
  }

  p {
    color: #FFF;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  }

  strong {
    background: linear-gradient(45deg, #FF4500, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding: 0 2px;
  }

  ul li {
    color: #FFF;
    position: relative;
    
    &::before {
      content: '✿';
      color: #32CD32 !important;
      position: absolute;
      left: -1.5rem;
      text-shadow: 0 0 5px rgba(50, 205, 50, 0.5);
    }

    &:hover::before {
      color: #FF4500 !important;
      text-shadow: 0 0 5px rgba(255, 69, 0, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FF4500, #32CD32);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 69, 0, 0.5),
        0 0 20px rgba(50, 205, 50, 0.3);
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FF4500, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
  }

  .veiled-link {
    color: #FFF !important;
    transition: all 0.3s ease;

    &:hover {
      color: #FF4500 !important;
      text-shadow: 0 0 8px rgba(255, 69, 0, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(255, 69, 0, 0.2);
      border: 1px solid rgba(50, 205, 50, 0.3);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(255, 69, 0, 0.3);
      color: #FF4500;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 69, 0, 0.1);
        border-color: #32CD32;
      }
    }
  }

  .deity-path-background {
    filter: brightness(1.1) contrast(1.1) saturate(1.2);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1.2) contrast(1.2) saturate(1.3);
    }
  }

  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const PantheonQueenTitania = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledWildgrowthPage>
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img
            src={queenTitaniaImage}
            alt="Queen Titania: The Radiant Sovereign of Summer"
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <motion.div 
            className="deity-path-title"
            variants={contentVariants}
          >
            <h1>Queen Titania: The Radiant Sovereign of Summer</h1>
            <div className="menu-container">
              <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
                <motion.h2 
                  onClick={toggleMenu}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Pantheon
                  <ChevronDown size={20} />
                </motion.h2>
                <div className="veiled-links" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                  <Link to="/Pantheon" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    ← Return to Veiled Pantheon
                  </Link>
                  <Link to="/deities/PantheonCaelumbris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Caelumbris
                  </Link>
                  <Link to="/deities/PantheonCalanthar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Calanthar
                  </Link>
                  <Link to="/deities/PantheonFirstborn" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Firstborn
                  </Link>
                  <Link to="/deities/PantheonLibris" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    L.I.B.R.I.S.
                  </Link>
                  <Link to="/deities/PantheonLeviathan" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Leviathan
                  </Link>
                  <Link to="/deities/PantheonNebula" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Nebula
                  </Link>
                  <Link to="/deities/PantheonQueenMab" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Queen Mab
                  </Link>
                  <Link to="/deities/PantheonSacrathor" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Sacrathor
                  </Link>
                  <Link to="/deities/PantheonValdis" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Valdís
                  </Link>
                  <Link to="/deities/PantheonWildscar" className="veiled-link" onClick={() => setMenuOpen(false)}>
                    Wildscar
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">The Summer Queen</h2>
          <p>
            Queen Titania embodies the radiance and vitality of summer, her presence evoking warmth and flourishing life. Her dominion is a realm of perpetual sunlight, where gardens bloom with otherworldly vibrancy and flowers glow softly as if kissed by the sun itself. The air hums with the mingling scents of blooming flora, the drone of bees, and the rustle of leaves, creating an immersive tapestry that invigorates the spirits of all who enter. The Seelie Court, which she presides over, is a sanctuary of unbridled artistic splendor and vibrant energy.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Description</h3>
          </div>
          <p>
            Titania represents summer in its most unbound form—a relentless force of creativity and abundant growth. Her beauty inspires awe, while her charisma draws followers to her court, compelling artists to craft their finest works and leaders to seek her wisdom in pivotal moments. Under her influence, bards compose enduring melodies, and architects design grand tributes that celebrate her radiance. However, her untamed nature mirrors the duality of summer's warmth: nurturing yet overwhelming when unchecked. Her reign reflects the delicate balance between flourishing vitality and chaotic overgrowth.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Origins and Mythos</h3>
          </div>
          <p>
            Titania's tale is one of luminous creation and boundless growth. Revered as a beacon of life and renewal, her influence has long shaped the realms of summer. Her actions inspire beauty and innovation, fostering an unyielding drive to create and flourish. Though her ambitions occasionally push the boundaries of natural order, they are celebrated as the essence of her vibrant and dynamic spirit.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Influence and Teachings</h3>
          </div>
          <p>
            Titania's teachings celebrate the cycles of growth and renewal, encouraging her followers to embrace the joys of life, creativity, and passion. She advocates for unrestrained creation, fostering incredible beauty and innovation but also risking the chaos of unchecked growth. Followers are taught to pursue their passions with abandon, often walking a fine line between flourishing life and destructive excess. This excess may manifest in overambition, resource depletion, or creations so overwhelming they destabilize the balance of the natural world. Her influence is both a gift and a challenge, inviting her followers to explore their potential while navigating the unpredictability of unrestrained energy. Titania's philosophy emphasizes the importance of creativity tempered by awareness of its consequences.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Worship and Clergy</h3>
          </div>
          <p>
            The worship of Titania is an exuberant expression of life's vibrancy. Her rituals are joyous celebrations filled with music, dance, and the vibrant colors of summer. Her clergy, clad in radiant gold and green robes, act as custodians of her teachings and guides to her followers. They celebrate life's flourishing beauty and encourage creative endeavors, though they often wrestle with channeling Titania's wild energies into constructive paths. Through their devotion, they seek to honor her vitality while mitigating the potential chaos of unbound growth.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Role in the Shattered Realm</h3>
          </div>
          <p>
            Titania's chaotic energy has made her a force of unbridled creation within the Shattered Realm, driving waves of wild growth that ripple across adjacent domains. These expansions challenge neighboring territories, forcing them to adapt to sudden influxes of life or risk being overwhelmed by relentless flora and energy. Her influence reshapes the Shattered Realm, creating new opportunities for life to flourish but often disrupting established harmony. While Titania's power inspires and uplifts, it also pushes the boundaries of balance, reshaping ecosystems in unpredictable ways. Her actions underscore her role as a catalyst for transformation—both celebrated and feared for the changes she brings to the natural order.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Powers and Abilities</h3>
          </div>
          <p>
            Titania wields the forces of light and growth with unparalleled mastery. She can inspire rapid flourishing of flora, imbue her followers with extraordinary charisma, and harness the sun's energy as both a nurturing warmth and a consuming blaze. Her beauty and charm are tools of persuasion and inspiration, driving artistic and creative achievements that ripple through the Seelie Court and the Shattered Realm. Her presence shapes her court into a haven of artistry and passion, while her influence extends beyond her borders, inspiring awe and action among those who seek her favor. Titania's abilities exemplify the boundless potential of creation—a source of beauty and transformation that defines her role as the Radiant Sovereign of Summer.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <h2>Divine Aspects</h2>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>Empress of the Seelie Court, The Sovereign of Summer's Wild Glory</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Light, Growth, Passion, Art</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>Seelie Court</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A sunburst entwined with blooming flora</p>
          </motion.div>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <img 
                src={queenTitaniaImage} 
                alt="Queen Titania: The Radiant Sovereign of Summer"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={queenTitaniaImage}
                download="QueenTitania.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledWildgrowthPage>
  );
};

export default PantheonQueenTitania; 